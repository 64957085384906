import {
  LOGIN_URL,
  USER_LOGGED_IN_COOKIE_NAME,
} from '@/redesign/lib/constants';
import { hasCookie } from '@/redesign/lib/utils/client/cookie';

export const getLoginUrl = (
  returnTo?: string,
  searchParams?: Record<string, string>,
) => {
  let url = LOGIN_URL;

  if (returnTo) {
    url += `?returnTo=${encodeURIComponent(returnTo)}`;
  }

  if (searchParams && searchParams) {
    const params = new URLSearchParams(searchParams);
    url += (returnTo ? '&' : '?') + params.toString();
  }

  return url;
};

export const isUserLoggedIn = () => {
  return hasCookie(USER_LOGGED_IN_COOKIE_NAME);
};
